<!--
 * @Description:客服用户服务管理 信息管理 官网公告管理 officialWebManage
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-19 11:31:54
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="公告标题">
              <el-input v-model="searchForm.announceTitle"
                        clearable
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="公告状态">
              <el-select v-model="searchForm.announceStatusCode"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in statusList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="公告时间">
              <el-date-picker v-model="noticeDate"
                              type="daterange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="search">查 询</el-button>

          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>公告管理</span>
        <el-button class="tableTitleButton"
                   type="success"
                   @click="addHandle">新 增</el-button>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  ref="tableRef"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="6">
                  <el-button type="text"
                             size="medium"
                             @click="detailsClick(scope.row)">详情</el-button>
                </el-col>
                <el-col :span="6">
                  <el-button type="text"
                             size="medium"
                             @click="editClick(scope.row)">修改</el-button>
                </el-col>
                <el-col :span="6">
                  <el-button type="text"
                             size="medium"
                             @click="deleteClick(scope.row)">删除</el-button>
                </el-col>
                <el-col :span="6">
                  <el-button type="text"
                             size="medium"
                             @click="publisClick(scope.row)">{{scope.row.announceStatusCode==1?'发布':'停止发布'}}</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <el-dialog :title="dialogTitle"
               :visible.sync="dialogVisible"
               append-to-body
               :close-on-click-modal="false"
               @close="popClose">
      <!-- 新增表单 -->
      <el-form label-width="150px"
               :model="popForm"
               ref="popForm"
               :rules="rules"
               :inline="true"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="公告标题"
                          prop="announceTitle">
              <el-input v-model="popForm.announceTitle"
                        :readonly="dialogType===2"
                        placeholder="公告标题"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公告内容描述"
                          prop="contentDesc">
              <el-input v-model="popForm.contentDesc"
                        :readonly="dialogType===2"
                        placeholder="公告内容描述"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-form-item v-if="dialogType!==2"
                        label="公告内容"
                        prop="content">
            <richText ref="richText"
                      v-model="popForm.content"
                      :height="420"
                      :uploadImgMaxLength="6"
                      @uploadImg="uploadImg"></richText>
          </el-form-item>
          <div v-else>
            <el-form-item label="公告内容"
                          prop="content">
            </el-form-item>
            <el-row class="richArea"
                    v-html="popForm.content"></el-row>
          </div>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end"
              v-show="dialogType!==2">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     @click="popSubmit('popForm')"
                     style="margin-left:17px;
                     border-radius:4px;
                     height:36px;">确 定</el-button>
          <el-button @click="dialogVisible = false"
                     type="info">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
import richText from "@/components/richText";
import Compressor from 'compressorjs'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
    richText
  },
  data () {
    //这里存放数据
    return {
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      pageNum: 1,
      pageSize: 15,
      tableColumnList: [
        {
          prop: 'announceTitle',
          label: '公告标题',
        },
        {
          prop: 'contentDesc',
          label: '公告内容描述',
        },
        {
          prop: 'announceStatusDesc',
          label: '公告状态',
        },
        {
          prop: 'createTime',
          label: '公告创建时间',
        },
        {
          prop: 'creater',
          label: '创建人',
        },
      ],
      tableList: { list: [] },//表格数据
      //查询表单
      searchForm: {
        announceTitle: '',
        announceStatusCode: null,
        startTime: '',
        endTime: '',
        pageNum: 1,
        pageSize: 15
      },
      statusList: [],//公告状态
      noticeDate: [],//时间
      //新增表单
      popForm: {
        // announceTitle: '',
        // contentDesc: '',
        // content: '',
      },
      dialogType: null,   // 弹出框类型 0新增，1修改，2详情
      dialogTitle: '',//弹出框标题
      dialogVisible: false,//弹出框显示
      rules: {
        announceTitle: [
          { required: true, message: '请输入公告编号', trigger: 'blur' },
        ],
        contentDesc: [
          { required: true, message: '请输入公告内容描述', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '请输入公告内容', trigger: 'blur' },
        ],
      },
      Pager: null,//获取分页器
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    //获取分页器组件
    this.Pager = this.$refs.tableRef.$children[1]
    this.getList()
    this.getAnnouncementStatus()
  },
  //方法集合
  methods: {
    // 初始化数据
    getList (map = { pageNum: 1, pageSize: 15 }) {
      if (this.noticeDate.length) {
        this.searchForm.startTime = this.noticeDate[0]
        this.searchForm.endTime = this.noticeDate[1]
      } else {
        this.searchForm.startTime = ''
        this.searchForm.endTime = ''
      }
      this.searchForm.pageNum = map.pageNum
      this.searchForm.pageSize = map.pageSize
      this.$officialWebManage.queryAnnounceList(this.searchForm).then((response) => {
        this.tableList.list = response.resultEntity.list
        this.paginationConfig.total = response.resultEntity.total
      })
    },
    //查询
    search () {
      this.Pager.internalCurrentPage = 1
      this.listMap.pageNum = 1
      this.pageNum = 1
      this.getList()
    },

    //新增
    addHandle () {
      this.dialogVisible = true
      this.dialogType = 0
      this.dialogTitle = '新增公告'
      this.popForm = {
        announceTitle: '',
        contentDesc: '',
        content: '',
      }
      // 设置富文本默认内容
      this.$nextTick(() => {
        this.$refs.richText.setValue('')
      })
    },

    //修改
    editClick (row) {
      this.dialogType = 1
      this.dialogVisible = true
      this.dialogTitle = '修改公告'
      this.popForm = this.$deepClone(row)
      // 设置富文本默认内容
      this.$nextTick(() => {
        this.$refs.richText.setValue(row.content)
      })
    },

    //提交按钮
    popSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogType === 0) {
            this.requestAdd()
          } else if (this.dialogType === 1) {
            this.requestUpdate()
          }
          this.dialogVisible = false
        } else {
          return false;
        }
      });
    },

    // 新增请求接口
    requestAdd () {
      this.$officialWebManage.addAnnounce(this.popForm).then((response) => {
        if (response.resultCode == 2000) {
          this.$message({ message: '新增成功', type: 'success' });
          this.dialogVisible = false
          this.getList()
        }
      })
    },

    // 修改请求
    requestUpdate () {
      this.$officialWebManage.updateAnnounce(this.popForm).then((response) => {
        if (response.resultCode == 2000) {
          this.$message({ message: '修改成功', type: 'success' });
          this.dialogVisible = false
          this.getList()
        }
      })
    },

    // 删除请求
    deleteClick (row) {
      this.$confirm('是否删除公告?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let info = [{ announceId: row.announceId }]
        this.$officialWebManage.deleteAnnounce(info).then((response) => {
          if (response.resultCode == 2000) {
            this.$message({ message: '删除成功', type: 'success' });
            this.getList()
          }
        })
      })
    },

    // 发布请求
    publisClick (row) {
      if (row.announceStatusCode === 1) {
        //如果未发布
        this.$confirm('是否发布公告?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let info = { announceId: row.announceId }
          this.$officialWebManage.publish(info).then((response) => {
            if (response.resultCode == 2000) {
              this.$message({ message: '发布成功', type: 'success' });
              this.getList()
            }
          })
        })
      } else if (row.announceStatusCode === 2) {
        // 如果已发布
        this.$confirm('是否停止发布公告?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let info = { announceId: row.announceId }
          this.$officialWebManage.stopPublish(info).then((response) => {
            if (response.resultCode == 2000) {
              this.$message({ message: '停止发布成功', type: 'success' });
              this.getList()
            }
          })
        })
      }
    },
    // 详情
    detailsClick (row) {
      this.dialogType = 2
      this.dialogVisible = true
      this.dialogTitle = '公告详情'
      this.popForm = this.$deepClone(row)
    },
    // 富文本上传图片
    uploadImg (resultFiles, insertImgFn) {
      // console.log("选择的图片列表", resultFiles, Compressor);
      resultFiles.forEach(file => {
        // 压缩图片
        const self = this
        new Compressor(file, {
          quality: 0.2,
          success (result) {
            // console.log("res", result);
            // 将 Blob 对象转换成 File 对象
            result = new window.File([result], result.name, { type: result.type })
            const formData = new FormData()
            formData.append('file', result)
            self.$queryDict.upFile(formData).then(res => {
              // console.log("上传成功", res);
              insertImgFn(self.$imgBaseUrl + res.resultEntity)
            }).catch(err => {
              console.log('图片上失败', err)
            })
          },
          error (err) {
            console.log('压缩失败', err)
          }
        })
      })
    },

    // 弹出层关闭
    popClose () {
      this.$refs.popForm.resetFields()
      this.popForm = {}
    },
    //重置
    resetForm () {
      this.noticeDate = []
      this.searchForm = {
        announceTitle: '',
        announceStatusCode: null,
        startTime: '',
        endTime: '',
        pageNum: 1,
        pageSize: 15
      }
      this.listMap.pageNum = 1
      this.pageNum = 1
      this.Pager.internalCurrentPage = 1
      this.getList()
    },
    //获取公告状态
    getAnnouncementStatus () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [{
          colName: 'parent_id',
          value: 'BB31X414V24X44BV44BV12424124BVCB',
        },],
      }
      this.$queryDict.queryDict(info).then((res) => {
        this.statusList = res.resultEntity
      })
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
.mainBody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      margin-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    // height: calc(100% - #{$topSelectHeight});
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.el-button--success {
  background: #019fe4;
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.dt-form-width {
  width: 230px;
}
.richArea {
  height: 500px;
  padding: 10px;
  background: #ffffff;
  overflow: auto;
}
/*修改滚动条样式*/
// 滚动条最底部样式
.richArea::-webkit-scrollbar {
  width: 5px;
  background: #ffffff;
  /**/
}
// 滚动条背景条样式
.richArea::-webkit-scrollbar-track {
  background: #ffffff;
}
// 滚动条上层可滑动的条样式
.richArea::-webkit-scrollbar-thumb {
  background: #004179;
  border-radius: 5px;
}
// 滚动条上层可滑动的条样式:hover样式
.richArea::-webkit-scrollbar-thumb:hover {
  background: #013b6d;
}

/deep/.el-textarea {
  width: 690px;
}
</style>